import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-section-header',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ RouterLink, FaIconComponent ],
    styles: `
        @import "../../../../styles/variables";
        @import "../../../../styles/functions";

        :host {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 1.5rem 0;

            h2 {
                font-size: 2.1rem;
                font-weight: 800;
                color: $black;
                width: fit-content;

                .count {
                    color: $footer-color;
                }

                &.subheadline {
                    font-size: 1.75rem;
                    font-weight: 700;
                }

                @include break(996px) {
                    font-size: 1.5rem;
                    font-weight: 700;

                    &.subheadline {
                        font-size: 1.25rem;
                        font-weight: 600;
                    }
                }
            }

            .btn {
                color: $black;
                border: solid 1px $grey;
                min-width: fit-content;
            }
        }
    `,
    template: `
        <h2 [class.subheadline]="isSubHeadline !== undefined">
            {{ headline }}
            @if (headlineCount !== undefined) {
                <span class="count">
              ({{ headlineCount }})
            </span>
            }
        </h2>

        @if (link) {
            <a class="btn rounded-full" routerLink="{{ link }}" [queryParams]="linkParams ?? {}">
                {{ buttonText }}
                <fa-icon [icon]="faArrowRight"/>
            </a>
        }
    `
})
export class SectionHeaderComponent {
    @Input({ required: true }) headline!: string;
    @Input() headlineCount?: number;
    @Input() isSubHeadline?: boolean | string;
    @Input() buttonText?: string;
    @Input() link?: string | number;
    @Input() linkParams?: Record<string, string>;
    faArrowRight = faArrowRight;
}
